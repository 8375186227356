import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fluidRange } from 'polished';
import { RichText } from 'prismic-reactjs';
import breakpoints from '../../../styles/settings/breakpoints';

const seo = ({ content }) => {
    const parseComponent = () => {
        if (content.title && content.text) {
            return (
                <SSeo>
                    <SSeoHeading>{content.title}</SSeoHeading>
                    <SSeoContent>{RichText.render(content.text)}</SSeoContent>
                </SSeo>
            );
        } else {
            return false;
        }
    };

    return parseComponent();
};

const SSeo = styled.div`
    ${fluidRange(
        {
            prop: 'margin-top',
            fromSize: '80px',
            toSize: '150px'
        },
        '320px',
        '1440px'
    )}

    padding-top: 60px;
    padding-bottom: 80px;
    border-top: 2px solid var(--color-gray-lighter);
    border-radius: 1px;
`;

const SSeoHeading = styled.h2`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '18px',
            toSize: '23px'
        },
        '320px',
        '1440px'
    )};
    margin-bottom: 20px;
    font-weight: 700;
`;

const SSeoContent = styled.div`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '15px',
            toSize: '18px'
        },
        '320px',
        '1440px'
    )}
    line-height: 1.5;

    strong {
        font-weight: 700;
    }

    a {
        text-decoration: underline;
        color: var(--color-secondary);
    }

    p + p {
        margin-top: 10px;
    }

    ${breakpoints.large} {
        column-count: 2;
        column-gap: 4em;

        p + p {
            margin-top: 0;
        }
    }
`;

export default seo;

seo.propTypes = {
    content: PropTypes.object
};
