import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Contain from '../components/base/contain';
import PanoramaDefault from '../components/parts/panoramas/panoramaDefault';
import Intro from '../components/parts/content/intro';
import SubscribeForm from '../components/parts/content/subscribeform';
import CardHorizontal from '../components/parts/cards/cardHorizontal';
import SeoBlock from '../components/parts/content/seo';
import breakpoints from '../styles/settings/breakpoints';

const Archive = ({ data }) => {
    const items = [];
    const prismicData = data.prismic.allArchives.edges[0]?.node;
    
    if (!prismicData) {
        return false;
    }

    const uid = prismicData._meta.uid;

    const panorama = {
        title: prismicData.panorama_title[0].text,
        text: prismicData.panorama_text[0].text,
        image: {
            src: prismicData.panorama_imageSharp.childImageSharp.fluid,
            alt: prismicData.panorama_image.alt
        }
    };

    const intro = {
        title: prismicData.intro_title?.[0].text,
        text: prismicData.intro_text
    };

    const { cards } = prismicData;

    const seo = {
        title: prismicData.seo_title ? prismicData.seo_title[0].text : '',
        text: prismicData.seo_text
    };

    for (const [index, { card }] of cards.entries()) {
        const link = card._meta.uid === 'het-team' ? card._meta.uid : `${uid}/${card._meta.uid}`;
        items.push(
            <CardHorizontal
                content={{
                    image: card.archive_imageSharp.childImageSharp.fluid,
                    title: card.title[0].text,
                    description: card.description,
                    link: link
                }}
                key={index}
            >
                {card.title}
            </CardHorizontal>
        );
    }

    return (
        <Layout>
            <SEO title={panorama.title} />
            <PanoramaDefault
                background={false}
                alignment="center"
                content={{
                    image: panorama.image,
                    title: panorama.title,
                    text: panorama.text
                }}
            />
            <Contain>
                <Intro intro={intro} />
                
                {uid === 'inschrijven' ? <SubscribeForm /> : null} {/* ONLY SHOW THIS ON INSCHRIJVEN PAGE */}

                <SCards>{items}</SCards>

                <SeoBlock content={seo} />
            </Contain>
        </Layout>
    );
};

const SCards = styled.div`
    margin-top: 60px;
    margin-bottom: 40px;

    ${breakpoints.large} {
        margin-top: 100px;
        margin-bottom: 80px;
    }
`;

export const pageQuery = graphql`
    query ArchiveQuery($uid: String) {
        prismic {
            allArchives(uid: $uid) {
                edges {
                    node {
                        _meta {
                            uid
                        }
                        panorama_image
                        panorama_imageSharp {
                            childImageSharp {
                                fluid(maxWidth: 2560, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        panorama_title
                        panorama_text
                        intro_title
                        intro_text
                        cards {
                            card {
                                ... on PRISMIC_Single {
                                    title
                                    description
                                    archive_image
                                    archive_imageSharp {
                                        childImageSharp {
                                            fluid(maxWidth: 2560, quality: 90) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                    _meta {
                                        uid
                                    }
                                }
                                ... on PRISMIC_Team {
                                    title
                                    description
                                    archive_image
                                    archive_imageSharp {
                                        childImageSharp {
                                            fluid(maxWidth: 2560, quality: 90) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                    _meta {
                                        uid
                                    }
                                }
                            }
                        }
                        seo_title
                        seo_text
                    }
                }
            }
        }
    }
`;

export default Archive;

Archive.propTypes = {
    data: PropTypes.object
};
