import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { fluidRange } from 'polished';
import Img from 'gatsby-image';
import Button from '../../base/button';
import breakpoints from '../../../styles/settings/breakpoints';

const CardHorizontal = ({ content }) => {
    const { title, description, image, link } = content;

    return (
        <SCard>
            <SCardImage>
                <Img alt={title} fluid={image} />
            </SCardImage>
            <SCardContent>
                <SCardHeading>{title}</SCardHeading>
                <SCardText>{RichText.render(description)}</SCardText>
                <SCardButton>
                    <Button to={link}>Lees meer</Button>
                </SCardButton>
            </SCardContent>
        </SCard>
    );
};

const SCardImage = styled.div`
    margin-bottom: 10px;

    ${breakpoints.medium} {
        margin-bottom: 0;
        width: 50%;
    }

    ${breakpoints.large} {
        width: 60%;
    }
`;

const SCardContent = styled.div`
    padding-top: 10px;

    ${breakpoints.medium} {
        padding-top: 0;
        width: 50%;
    }

    ${breakpoints.large} {
        width: 40%;
    }
`;

const SCardHeading = styled.div`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '18px',
            toSize: '23px'
        },
        '320px',
        '1440px'
    )}
    margin-bottom: 6px;
    font-weight: 700;

    ${breakpoints.medium} {
        margin-bottom: 12px;
    }
`;

const SCardText = styled.div`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '15px',
            toSize: '18px'
        },
        '320px',
        '1440px'
    )}
    line-height: 1.5;
`;

const SCardButton = styled.div`
    margin-top: 14px;
`;

const SCard = styled.div`
    border-radius: 3px;
    overflow: hidden;

    & + & {
        margin-top: 50px;
    }

    ${breakpoints.medium} {
        display: flex;

        & + & {
            margin-top: 100px;
        }

        &:nth-child(odd) {
            ${SCardContent} {
                padding-left: 30px;
            }
        }

        &:nth-child(even) {
            flex-direction: row-reverse;

            ${SCardContent} {
                padding-right: 30px;
            }
        }
    }
`;

export default CardHorizontal;

CardHorizontal.propTypes = {
    content: PropTypes.object.isRequired
};
