import React from 'react';
import styled from 'styled-components';

const Checkbox = ({ name, id, value, onChange, disabled, label, required = false }) => {
    return (
        <SWrapper>
            <SCheckbox type="checkbox" id={id} name={name} value={value} onChange={onChange} disabled={disabled} required={required} />
            <SLabel htmlFor={id} dangerouslySetInnerHTML={{__html: label}} />
        </SWrapper>
    );
};

const SWrapper = styled.div`

    &:not(:last-child) {
        margin-bottom: 10px;
    }
`;

const SCheckbox = styled.input`
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;

    &:required:invalid {

        & ~ label {
            color: var(--color-error);
        }
    }

    & ~ label {
		display: inline-block;
		position: relative;
		padding-left: 22px;
		font-family: $font-title;
        color: var(--color-light-black);
		transition: color .24s ease-in;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 2px;
			left: 0;
		}

		&::before {
			width: 16px;
			height: 16px;
			transition: border-color $tempo, box-shadow $tempo;
			border: 1px solid var(--color-primary);
            border-radius: 3px;
		}

		&::after {
			width: 14px;
			height: 14px;
			margin: 1px;
			transform: scale(0);
			transform-origin: center center;
			transition: transform .24s ease-in-out, opacity .24s ease-in;
            background: var(--color-primary);
			opacity: 0;
            border-radius: 2px;
		}
	}

	&:hover,
	&:focus {

		& ~ label {

			&::before {
				border-color: var(--color-primary);
			}
		}
	}

	&:disabled {

		& ~ label {
			opacity: .35;
		}
	}

	&:checked {

		& ~ label {
			color: var(--color-black);

			&::after {
				transform: scale(.7);
				opacity: 1;
			}
		}
	}
`;

const SLabel = styled.label`
    
    a {
        text-decoration: underline;
        font-weight: 600;
    }
`;

export default Checkbox;
