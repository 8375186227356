import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { position, size, fluidRange } from 'polished';
import Contain from '../../base/contain';
import Img from 'gatsby-image';

const PanoramaDefault = ({ background, alignment, content }) => {
    const showImage = () => {
        if (!background) {
            return (
                <SPanoramaImage>
                    <Img alt={content.image.alt} fluid={content.image.src} />
                </SPanoramaImage>
            );
        }
    };

    return (
        <SPanorama background={background}>
            {showImage()}

            <Contain>
                <SPanoramaContent alignment={alignment}>
                    <SPanoramaHeading>{content.title}</SPanoramaHeading>
                    <SPanoramaText alignment={alignment}>{content.text}</SPanoramaText>
                </SPanoramaContent>
            </Contain>
        </SPanorama>
    );
};

const SPanorama = styled.div`
    position: relative;
    background-color: ${prop => (!prop.background ? 'transparent' : 'var(--color-primary)')};
`;

const SPanoramaImage = styled.figure`
    &,
    .gatsby-image-wrapper > div {
        ${position('absolute', '0', null, null, '0')}
        ${size('100%', '100%')}
    }

    .gatsby-image-wrapper {
        height: 100%;
    }

    &::after {
        ${position('absolute', '0', null, null, '0')}
        ${size('100%', '100%')}
        background-color: var(--color-black);
        opacity: 0.4;
        content: '';
    }
`;

const SPanoramaContent = styled.div`
    position: relative;
    margin-right: ${props => (props.alignment === 'center' ? 'auto' : '0')};
    margin-left: ${props => (props.alignment === 'center' ? 'auto' : '0')};
    text-align: ${prop => prop.alignment};
    color: var(--color-white);

    ${fluidRange(
        {
            prop: 'padding-top',
            fromSize: '150px',
            toSize: '190px'
        },
        '320px',
        '1440px'
    )}
    ${fluidRange(
        {
            prop: 'padding-bottom',
            fromSize: '140px',
            toSize: '160px'
        },
        '320px',
        '1440px'
    )};
`;

const SPanoramaHeading = styled.h1`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '40px',
            toSize: '80px'
        },
        '320px',
        '1000px'
    )}

    font-weight: 700;
    line-height: 1;
`;

const SPanoramaText = styled.p`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '20px',
            toSize: '26px'
        },
        '320px',
        '1000px'
    )}

    max-width: 540px;
    margin-top: 10px;
    margin-right: ${props => (props.alignment === 'center' ? 'auto' : '0')};
    margin-left: ${props => (props.alignment === 'center' ? 'auto' : '0')};
    font-weight: 300;
    line-height: 1.3;
`;

export default PanoramaDefault;

PanoramaDefault.propTypes = {
    background: PropTypes.bool,
    alignment: PropTypes.string,
    content: PropTypes.object.isRequired
};
