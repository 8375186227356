import React from 'react';
import styled from 'styled-components';

const Loader = ({ visible }) => {
    return (
        <SWrapper visible={visible}>
            <SLoader>
                <SDot />
                <SDot />
            </SLoader>
        </SWrapper>
    );
};

const SWrapper = styled.div`
    transform: ${props => (props.visible ? 'scale(1)' : 'scale(0)')};
    transition: transform .24s ease-in;
`;

const SLoader = styled.div`
    margin-left: 30px;
    width: 20px;
    height: 20px;
    position: relative;
    text-align: center;
    animation: sk-rotate 2.0s infinite linear;

    @keyframes sk-rotate { 
        100% { 
            transform: rotate(360deg);
        }
    }
`;

const SDot = styled.span`
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: var(--color-primary);
    border-radius: 100%;
    animation: sk-bounce 2.0s infinite ease-in-out;

    &:last-child {
        top: auto;
        bottom: 0;
        animation-delay: -1.0s;        
    }

    @keyframes sk-bounce {
        0%, 100% { 
            transform: scale(0.0);
        } 50% { 
            transform: scale(1.0);
        }
    }
`

export default Loader;
