import React from 'react';
import styled from 'styled-components';

const Notice = ({ children, visible, type }) => {
    return (
        <SNotice visible={visible} type={type}>
            {children}
        </SNotice>
    );
};

const SNotice = styled.div`
    display: ${props => (props.visible ? 'block' : 'none')};
    border: 1px solid ${props => (props.type === 'error' ? 'var(--color-error)' : 'var(--color-primary)')};
    background-color: ${props => (props.type === 'error' ? 'rgba(222, 105, 105, .8)' : 'rgba(92, 160, 142, .8)')};
    background-color: ;
    color: white;
    font-weight: 600;
    padding: 14px 20px;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

export default Notice;
