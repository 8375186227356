import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { fluidRange } from 'polished';

const intro = ({ intro }) => {
    if (intro || intro.text[0].text) {
        return (
            <SIntro>
                <SHeading>{intro.title}</SHeading>
                <SText>{RichText.render(intro.text)}</SText>
            </SIntro>
        );
    } else {
        return false;
    }
};

const SIntro = styled.div`
    padding-top: 40px;
`;

const SHeading = styled.h2`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '18px',
            toSize: '23px'
        },
        '320px',
        '1440px'
    )}
    margin-bottom: 12px;
    font-weight: 700;
`;

const SText = styled.div`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '15px',
            toSize: '18px'
        },
        '320px',
        '1440px'
    )}
    line-height: 1.5;
    color: var(--color-gray-darker);
    max-width: 760px;
`;

export default intro;

intro.propTypes = {
    intro: PropTypes.object
};
