import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import CheckIcon from '../../images/icons/check.svg';
import breakpoints from '../../styles/settings/breakpoints';

const Daypicker = ({handleDayChange, disabled}) => {
    const items = [];

    const days = {
        monday: {
            label: 'Maandag',
            key: 'monday',
            morning: 0,
            midday: 0,
            options: {
                firstMorning: {
                    type: 1,
                    label: 'Ochtend',
                    slug: 'morning',
                    checked: false
                },
                firstMidday: {
                    type: 1,
                    label: 'Middag',
                    slug: 'midday',
                    checked: false
                },
                secondMorning: {
                    type: 2,
                    label: 'Ochtend',
                    slug: 'morning',
                    checked: false
                },
                secondMidday: {
                    type: 2,
                    label: 'Middag',
                    slug: 'midday',
                    checked: false
                }
            }
        },
        tuesday: {
            label: 'Dinsdag',
            key: 'tuesday',
            morning: 0,
            midday: 0,
            options: {
                firstMorning: {
                    type: 1,
                    label: 'Ochtend',
                    slug: 'morning',
                    checked: false
                },
                firstMidday: {
                    type: 1,
                    label: 'Middag',
                    slug: 'midday',
                    checked: false
                },
                secondMorning: {
                    type: 2,
                    label: 'Ochtend',
                    slug: 'morning',
                    checked: false
                },
                secondMidday: {
                    type: 2,
                    label: 'Middag',
                    slug: 'midday',
                    checked: false
                }
            }
        },
        wednesday: {
            label: 'woensdag',
            key: 'wednesday',
            morning: 0,
            midday: 0,
            options: {
                firstMorning: {
                    type: 1,
                    label: 'Ochtend',
                    slug: 'morning',
                    checked: false
                },
                firstMidday: {
                    type: 1,
                    label: 'Middag',
                    slug: 'midday',
                    checked: false
                },
                secondMorning: {
                    type: 2,
                    label: 'Ochtend',
                    slug: 'morning',
                    checked: false
                },
                secondMidday: {
                    type: 2,
                    label: 'Middag',
                    slug: 'midday',
                    checked: false
                }
            }
        },
        thursday: {
            label: 'Donderdag',
            key: 'thursday',
            morning: 0,
            midday: 0,
            options: {
                firstMorning: {
                    type: 1,
                    label: 'Ochtend',
                    slug: 'morning',
                    checked: false
                },
                firstMidday: {
                    type: 1,
                    label: 'Middag',
                    slug: 'midday',
                    checked: false
                },
                secondMorning: {
                    type: 2,
                    label: 'Ochtend',
                    slug: 'morning',
                    checked: false
                },
                secondMidday: {
                    type: 2,
                    label: 'Middag',
                    slug: 'midday',
                    checked: false
                }
            }
        },
        friday: {
            label: 'Vrijdag',
            key: 'friday',
            morning: 0,
            midday: 0,
            options: {
                firstMorning: {
                    type: 1,
                    label: 'Ochtend',
                    slug: 'morning',
                    checked: false
                },
                firstMidday: {
                    type: 1,
                    label: 'Middag',
                    slug: 'midday',
                    checked: false
                },
                secondMorning: {
                    type: 2,
                    label: 'Ochtend',
                    slug: 'morning',
                    checked: false
                },
                secondMidday: {
                    type: 2,
                    label: 'Middag',
                    slug: 'midday',
                    checked: false
                }
            }
        }
    };

    const [selections, setSelections] = useState(days);

    const [selected, setSelected] = useState({
        monday_morning: 0,
        monday_midday : 0,
        tuesday_morning : 0,
        tuesday_midday : 0,
        wednesday_morning : 0,
        wednesday_midday : 0,
        thursday_morning : 0,
        thursday_midday : 0,
        friday_morning : 0,
        friday_midday : 0,
    });

    useEffect(() => {
        handleDayChange(selected);
    }, [selected]);

    const handleChange = (day, item) => {
        const newValue = `${day.key}_${item.slug}`;
        
        const type = item.label === 'Ochtend' ? 'morning' : 'midday' ;

        item.checked = !item.checked;

        if(item.checked) {
            day[type] = item.type;
        } else {
            day[type] = 0;
        }

        for (const key in day.options) {
            const option = day.options[key];

            if(option !== item && option.label === item.label) {
                option.checked = false;
                option.disabled = true;
            }
        }

        setSelected({ ...selected, [newValue]: item.type });
    };

    for (const key in selections) {
        const selects = [];
        const day = selections[key];
        
        for (const index in day.options) {
            const item = day.options[index];

            selects.push(
                <SDayPickerSelect key={`${day.key}-${item.slug}-${item.type}`}>
                    <SDayPickerInput type="checkbox" checked={item.checked} onChange={event => handleChange(day, item)} disabled={disabled}/>
                    <SDayPickerLabel isChecked={item.checked}>
                        {item.label}
                        <span>
                            <CheckIcon />
                        </span>
                    </SDayPickerLabel>
                </SDayPickerSelect>
            );
        }

        items.push(
            <SDayPickerItem key={day.key}>
                <SDayPickerDay>{day.label}</SDayPickerDay>
                <SDayPickerSelectWrapper>
                    {selects}
                </SDayPickerSelectWrapper>
            </SDayPickerItem>
        );
    }

    return (
        <SDayPicker>
            <SDayPickerHeader>
                <span>Eerste keuze</span>
                <span>Tweede keuze</span>
            </SDayPickerHeader>
            <SDayPickerDays>
                {items}
            </SDayPickerDays>

            <input type="hidden" name="monday_morning" />
            <input type="hidden" name="monday_midday" />
            <input type="hidden" name="tuesday_morning" />
            <input type="hidden" name="tuesday_midday" />
            <input type="hidden" name="wednesday_morning" />
            <input type="hidden" name="wednesday_midday" />
            <input type="hidden" name="thursday_morning" />
            <input type="hidden" name="thursday_midday" />
            <input type="hidden" name="friday_morning" />
            <input type="hidden" name="friday_midday" />
        </SDayPicker>
    )
};

const SDayPicker = styled.div`
    display: flex;
    align-items: start;
    flex-wrap: wrap;
`;

const SDayPickerHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    
    span {
        width: 50%;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        font-weight: 600;
    }

    ${breakpoints.large} {
        span {
            width: 43%;
        }
    }
`;

const SDayPickerDays = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SDayPickerDay = styled.span`
    width: 100%;
    padding-top: 14px;
    padding-bottom: 6px;
    font-weight: 600;

    ${breakpoints.large} {
        width: 14%;
        padding-bottom: 14px;
    }
`;

const SDayPickerItem = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const SDayPickerSelectWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    ${breakpoints.large} {
        width: 86%;
    }
`;

const SDayPickerSelect = styled.div`
    position: relative;
    padding: 20px 0;
    border: 4px solid var(--color-background);
    border-radius: 4px;
    width: 25%;
    height: 100%;

    ${breakpoints.large} {
        padding: 0;
    }
`;

const SDayPickerInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:disabled {
        cursor: default;
    }
`;

const SDayPickerLabel = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(${props => (props.isChecked ? '--color-primary' : '--color-gray')});
    border-radius: 4px;
    transition: background-color .24s ease-in;
    color: var(--color-white);
    font-weight: 600;

    span {
        display: none;
    }

    ${breakpoints.compact} {

        span {
            content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 4px;
            left: 4px;
            width: 14px;
            height: 14px;
            transform: ${props => (props.isChecked ? 'scale(1)' : 'scale(0)')};
            background-color: var(--color-white);
            border-radius: 50%;
            transition: transform .24s ease-in;

            svg {
                width: 10px;
            }
        }
    }
`;

export default Daypicker;
